import React, { useContext, createContext, FunctionComponent } from 'react';
import { create } from 'mobx-persist';
import { AgentStore } from '../store';

export const store: AgentStore = new AgentStore();

export const AgentContext = createContext(store);

if (typeof window !== 'undefined') {
  const hydrate = create();
  hydrate('userAgentStore', store)
    .catch(err => console.warn('Hydrate Err', err));
}

export const AgentStoreProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => (
  <AgentContext.Provider value={store}>{children}</AgentContext.Provider>
);

export const useAgentStore = () => useContext(AgentContext);
