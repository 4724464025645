const Config = {
  dev: true,
  api: {
    url:
      process.env.NODE_ENV !== 'production'
        ? 'http://belovd.test:3000/api/'
        // ? 'https://api.icatched.de/api/'
          : 'https://api.icatched.de/api/'
  },
  moderationLink: 'https://chocolate-life.com/view-conversation'
};

export default Config;
