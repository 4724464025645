import axios from 'axios';
import { isArray } from 'lodash';
import Router from 'next/router';
import Config from '../components/config';

const adminCenterClient = axios.create({
  baseURL: Config.api.url,
  headers: {
    'x-client-name': 'webversion'
  }
});

const handleMaintenance = () => {
  if (process.env.NODE_ENV !== 'production') console.log('Maintenance-Modus');

  Router.push({
    pathname: '/maintenance',
    query: { maintenance: true }
  });
};

export const SetToken = (token: string) => {
  //if (process.env.NODE_ENV !== 'production') console.log('SetToken', token);
  adminCenterClient.defaults.headers.Authorization = `Bearer ${token}`;
};

export const RemoveToken = () => {
  if (process.env.NODE_ENV !== 'production') console.log('RemoveToken');
  adminCenterClient.defaults.headers.Authorization = null;
};

adminCenterClient.interceptors.response.use(
  response => {
    if (process.env.NODE_ENV !== 'production') console.log('RES', response);

    return response;
  },
  error => {
    console.warn('responseError', error);
    if (error.response) {
      if (error.response.status === 503) {
        handleMaintenance();
      }
      if (error.response?.data?.statusCode === 20000) {
        RemoveToken();
        Router.push('/');
      }
    }
    const errorValue = (error.response && error.response.data) || error;
    return Promise.reject(errorValue);
  }
);

export async function Login(
  username: string,
  password: string,
  agencyShort: string
) {
  const {
    data: {
      items: {
        accessToken: token,
        agency,
        team,
        agent: { name, id, alias, role },
        permission: { allowActions }
      }
    }
  } = await adminCenterClient.post('admincenter/auth/login', {
    username,
    password,
    agencyShort,
    'scope[]': '*'
  });
  SetToken(token);
  return {
    agency,
    team,
    agent: {
      name,
      id,
      alias,
      role
    },
    token,
    permissions: allowActions
  };
}

export function Logout(force = false) {
  return adminCenterClient
    .get('admincenter/auth/logout', { params: { force } })
    .then(({ data: { statusCode } }) => {
      const success = statusCode === 200;
      if (success) RemoveToken();
      return success;
    });
}

export const useFormData = {
  baseURL: Config.api.url,
  headers: {
    'x-client-name': 'webversion'
  },
  transformRequest: [
    (data: any) => {
      if (!data) {
        return null;
      }
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (isArray(value)) {
          value.forEach(item => formData.append(`${key}[]`, item));
        } else if (typeof value !== 'undefined') {
          // @ts-ignore
          formData.append(key, value);
        }
      });
      return formData;
    },
    ...axios.defaults.transformRequest
  ]
};

export default adminCenterClient;
