import { observable, action } from 'mobx';
import { persist } from 'mobx-persist';
import { Config } from '../../services/configuration';
import { User, Agent, Team, Agency } from '../../services/login';
import { Permissions } from '../types';
import 'mobx-react-lite/batchingForReactDom';

class AgentStore {
  isLoading = false;

  @persist('object') @observable agent: Agent | undefined = undefined;

  @persist('object') @observable agency: Agency | undefined = undefined;

  @persist('object') @observable team: Team | undefined = undefined;

  @persist('object') @observable config: Config | undefined = undefined;

  @persist('object')
  @observable
  permissions: Permissions = {};

  @action
  setAgent = (data: User) => {
    const { agent, agency, team, permissions } = data;
    this.agent = agent;
    this.agency = agency;
    this.team = team;
    const obj: Permissions = {};
    const wat = permissions.forEach(key => {
      // @ts-ignore
      obj[key] = true;
    });
    this.permissions = obj;
  };

  @action
  setConfig = (config: Config) => {
    this.config = config;
  };

  resetAgent = () => {
    this.agent = undefined;
    this.agency = undefined;
    this.team = undefined;
    this.permissions = {};
    this.config = undefined;
  };
}

export default AgentStore;
