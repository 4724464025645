import { decorate, observable, action } from 'mobx';
import { v4 as uuid } from 'uuid';
import { Conversation } from '../../services/conversation';
import { getLiveConversations } from '../../services/liveConverations';
import 'mobx-react-lite/batchingForReactDom';

const testObject = {
  id: uuid(),
  entryId: 1,
  users: [
    {
      id: '1',
      name: 'Marc',
      age: 28,
      image: {
        url: 'https://upload.wikimedia.org/wikipedia/commons/1/12/Testbild.png'
      },
      isMod: false
    },
    {
      id: '40',
      name: 'Kevin',
      age: 21,
      image: {
        url: 'https://upload.wikimedia.org/wikipedia/commons/1/12/Testbild.png'
      },
      isMod: true
    }
  ],
  language: 'de',
  moderatedUserId: '40',
  createdAt: '1992-11-02T23:00:00.000Z',
  moderator: {
    id: '1',
    teamId: '1',
    name: 'Kevin',
    agencyId: '1',
    teamName: 'Die Deppen'
  },
  dialoginfo: {
    actionFrom: '1992-11-02T21:00:00.000Z',
    actionTo: '1992-11-02T21:00:00.000Z',
    reason: 'Weil Du Dumm bist !!'
  }
};

class LiveDialogStore {
  isLoading = false;

  liveDialogs: Conversation[] = [];

  constructor() {
    this.fetchDialogs();
  }

  fetchDialogs = async () => {
    this.isLoading = true;
    // const data = await getLiveConversations();
    // this.liveDialogs = data;
    this.isLoading = false;
  };

  // testweise für adden durch WebSocket
  addDialog = () => {
    this.liveDialogs = [...this.liveDialogs, { ...testObject, id: uuid() }];
  };
}

decorate(LiveDialogStore, {
  liveDialogs: observable,
  fetchDialogs: action,
  isLoading: observable
});

export default LiveDialogStore;
