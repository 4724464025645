import { decorate, observable, action } from 'mobx';
import {
  getCountsDashboard,
  InOutHistory,
  getInOutDashboard,
  Dialogs
} from '../../services/dashboardStatistics';
import delay from '../../services/delay';
import 'mobx-react-lite/batchingForReactDom';

const initialDialogs: Dialogs = {
  open: 0,
  associated: 0,
  asa: 0,
  restrooms: 0,
  frozen: 0,
  asaByLang: {},
  openByLang: {}
};

const initialHistory: InOutHistory = {
  today: {
    in: 0,
    out: 0
  },
  yesterday: {
    in: 0,
    out: 0
  },
  week: {
    in: 0,
    out: 0
  },
  month: {
    in: 0,
    out: 0
  },
  lastMonth: {
    in: 0,
    out: 0
  }
};

class DashboardStore {
  isLoading = false;

  dialogs: Dialogs = initialDialogs;

  inOutHistory: InOutHistory = initialHistory;

  constructor() {
    this.fetchStatistics();
  }

  fetchStatistics = async () => {
    // const dialogs = await getCountsDashboard();
    // const inOutHistory = await getInOutDashboard();
    // this.dialogs = dialogs;
    // this.inOutHistory = inOutHistory;
  };

  initSocket = async () => {
    await delay(5000);
    this.fetchStatistics();
    this.initSocket();
  };
}

decorate(DashboardStore, {
  dialogs: observable,
  inOutHistory: observable,
  fetchStatistics: action,
  isLoading: observable
});

export default DashboardStore;
